@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
app-root{
  display: flex;
  flex-direction: column;
}
app-main-toolbar::after {
  height: 4px;
  display: block;
  top: 100%;
  right: 0;
  left: 0;
  background: linear-gradient(rgba(9, 30, 66, 0.13) 0px, rgba(9, 30, 66, 0.13) 1px, rgba(9, 30, 66, 0.08) 1px, rgba(9, 30, 66, 0) 4px);
  content: '';
}

//Fixes issue with Tailwind and Material 15. With new version in future should be able to remove
.mdc-notched-outline__notch {
  border-right: none;
}

.mat-calendar-body-selected {
  @apply bg-primary-400 text-light;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  @apply bg-primary-200;
}
.mat-calendar-body-today{
  @apply bg-primary-200;
}


.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  @apply bg-primary-400 text-light;
}







